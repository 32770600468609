import cn from "classnames";
import React from "react";
import "./action-button.scss";

interface Props {
  text: string;
  colorScheme?: "primary" | "secondary" | "third";
  variant?: "solid" | "outline";
  href?: string;
  onClick?: Function;
  className?: string;
  isDisabled?: boolean;
  isExternal?: boolean;
  size?: "sm" | "md" | "lg";
  dataGumroadSingleProduct?: boolean;
}

export const ActionButton = ({
  text,
  colorScheme,
  variant,
  href,
  onClick,
  className,
  isDisabled,
  isExternal,
  size,
  dataGumroadSingleProduct,
}: Props) => {
  // console.log("action text is:: ", text);
  return (
    <div
      className={cn("action--container", {
        "action--container--lg": size === "lg",
        "action--container--md": size === "md",
      })}
      onClick={() => onClick && onClick()}
    >
      {(onClick && (
        <div
          className={cn("action", className, {
            "action--primary": colorScheme === "primary",
            "action--secondary": colorScheme === "secondary",
            "action--third": colorScheme === "third",
            "action--outline": variant === "outline",
            "action--solid": variant === "solid",
            "action--disabled": isDisabled === true,
          })}
        >
          {text}
        </div>
      )) ||
        (dataGumroadSingleProduct && (
          <a
            className={cn("action", className, {
              "action--primary": colorScheme === "primary",
              "action--secondary": colorScheme === "secondary",
              "action--third": colorScheme === "third",
              "action--outline": variant === "outline",
              "action--solid": variant === "solid",
              "action--disabled": isDisabled === true,
            })}
            href={isExternal ? href : `/${href || "contact"}/`}
            target={isExternal ? "_blank" : ""}
            data-gumroad-single-product="true"
          >
            {text}
          </a>
        )) || (
          <a
            className={cn("action", className, {
              "action--primary": colorScheme === "primary",
              "action--secondary": colorScheme === "secondary",
              "action--third": colorScheme === "third",
              "action--outline": variant === "outline",
              "action--solid": variant === "solid",
              "action--disabled": isDisabled === true,
            })}
            href={isExternal ? href : `/${href || "contact"}/`}
            target={isExternal ? "_blank" : ""}
          >
            {text}
          </a>
        )}
    </div>
  );
};
