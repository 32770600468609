import React from "react";
import { gql, useQuery } from "urql";
import {
  FaGithub,
  FaReddit,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaMedium,
  FaDiscord,
  FaYoutube,
} from "react-icons/fa";

import { SiMedium } from "react-icons/si";

import github from "../assets/fa/github.svg";
import facebook from "../assets/fa/facebook.svg";
import youtube from "../assets/fa/youtube.svg";
import discord from "../assets/fa/discord.svg";
import instagram from "../assets/fa/instagram.svg";
import linkedin from "../assets/fa/linkedin.svg";
import twitter from "../assets/fa/twitter.svg";
import medium from "../assets/fa/medium.svg";

import "./social.scss";
import { MailingList } from "./mailing-list";

const ALL_SOCIAL_QUERY = gql`
  query allSocial {
    allSocial(sort: { priority: ASC }) {
      name
      url
      enabled
      icon {
        asset {
          url
        }
      }
    }
  }
`;

export const Social = () => {
  const [result] = useQuery({ query: ALL_SOCIAL_QUERY });
  const { data, fetching, error } = result;
  return (
    <footer className="app-footer">
      <div className="app-footer--core">
        {data &&
          data.allSocial
            .filter((i) => i.enabled)
            .map((social: any, i: number) => (
              <a
                rel="noopener"
                key={i}
                title={social.name}
                href={social.url}
                target="_blank"
                className="social-item"
              >
                {(social.name === "Facebook" && (
                  <img alt="Facebook" src={facebook} />
                )) ||
                  (social.name === "Instagram" && (
                    <img alt="Instagram" src={instagram} />
                  )) ||
                  (social.name === "LinkedIn" && (
                    <img alt="LinkedIn" src={linkedin} />
                  )) ||
                  (social.name === "Reddit" && (
                    <img alt="Reddit" src={github} />
                  )) ||
                  (social.name === "GitHub" && (
                    <img alt="GitHub" src={github} />
                  )) ||
                  (social.name === "Twitter" && (
                    <img alt="Twitter" src={twitter} />
                  )) ||
                  (social.name === "YouTube" && (
                    <img alt="YouTube" src={youtube} />
                  )) ||
                  (social.name === "Discord" && (
                    <img alt="Discord" src={discord} />
                  )) ||
                  (social.name === "Medium" && (
                    <img alt="Medium" src={medium} />
                  ))}
              </a>
            ))}
      </div>
      <div className="app-footer--mailing-list">
        <p className="app-footer--mailing-list--text">Join our Mailing List</p>
        <MailingList />
        <p className="app-footer--footnote">
          Hyper Brew LLC - Custom Adobe Plugins for Video Teams
        </p>
      </div>
    </footer>
  );
};
